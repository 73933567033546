<template>
  <link rel="stylesheet" href="https://cdn.staticfile.org/font-awesome/4.7.0/css/font-awesome.css">
  <div class="bac">
    <div class="listBac">
      <div class="info_card_top">
        <div>
          <a>全部项目</a>
          <i></i>
        </div>
        <div >
          <a>待支付</a>
          <i></i>
        </div>
        <div style="margin-right: 3.9rem;">
          <a>已完成</a>
          <i></i>
        </div>
        <input type="text" placeholder="请输入项目名称" style="color: #212121">
        <button>查询</button>
      </div>
      <div class="info_card_medium">
        <div>订单详情</div>
        <i class="fa fa-angle-down" style="font-size:.24rem"></i>
        <div>订单状态</div>
        <i class="fa fa-angle-down" style="font-size:.24rem"></i>
        <div>价格</div>
        <i class="fa fa-angle-down" style="font-size:.24rem"></i>
        <div>操作</div>
      </div>
      <div class="list">

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Posted"
}
</script>

<style scoped>
.bac{
  width: 19.2rem;
  padding-left: 2.98rem;
  padding-top: .5rem;
  background-color: #f5f5f5;
}

.listBac{
  width: 14.6rem;
  height: 7.2rem;
  margin-left: .7rem;
  background-color: #ffffff;
}

.listBac .info_card_top{
  width: 12.3rem;
  height: .95rem;
  background-color: #ffffff;
  margin-left: .5rem;
  border: .01rem solid transparent;
  border-bottom: .02rem solid #acaeab;
  margin-bottom: .15rem;
  padding-left: .5rem;
}

.listBac .info_card_top div{
  float: left;
  width: 1.2rem;
  height: 1rem;
  line-height: 1rem;
  font-size: .21rem;
  font-weight: 600;
  color: #212121;
  margin-right: .3rem;
  box-sizing: border-box;
}

.listBac .info_card_top div i{
  display: block;
  width: 0rem;
  height: .04rem;
  background-color: #ec414d;
  transition: all 0.7s;
}

.listBac .info_card_top a{
  display: block;
  width: 100%;
  height: .94rem;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  /*font-size: .2rem;*/
  color: #212121;
}

.listBac .info_card_top div:hover i{
  width: 100%;
}

.listBac .info_card_top input{
  width: 2.2rem;
  height: .35rem;
  color: #dcdcdc;
  margin-top: .4rem;
  margin-right: .18rem;
  padding-left: .1rem;
  border: .01rem solid #dcdcdc;
  border-radius: .05rem;
  font-size: .14rem;
}

.listBac .info_card_top button{
  width: 1rem;
  height: .35rem;
  margin-top: .4rem;
  font-size: .16rem;
  background-color: #ec414d;
  color: #ffffff;
  border-radius: .05rem;
}

.listBac .info_card_medium{
  width: 12.3rem;
  height: .45rem;
  padding-left: 1rem;
  background-color: #fdf9f8;
  margin-left: .5rem;
  margin-bottom: .5rem;
}

.listBac .info_card_medium div{
  float: left;
  /* width: .65rem; */
  height: .18rem;
  font-size: .16rem;
  margin-top: .1rem;
  /*margin-top: .1rem;*/
  margin-right: .1rem;
}

.listBac .info_card_medium i{
  display: block;
  float: left;
  margin-right: .9rem;
  margin-top: .11rem;
}

.listBac .list{
  width: 14.5rem;
  height: 3rem;
  background: url("../../../../images/resources.png") no-repeat center;
  background-size: 10% auto;
}



</style>